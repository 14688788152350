<template>
  <div class="pane-wrapper">
    <div class="table-wrapper">
      <y-table
        :columns="columns"
        :loading="loading"
        :service="service"
        :entity="entity"
        :filters="privateFilters"
        :sorter="sorter"
        @add="handleClickAdd"
      >
        <template slot="action" slot-scope="action">
          <a-button type="link" @click="handleEditClick(action.record)"
            >编辑</a-button
          >
          <a-popconfirm
            title="是否确认删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDeleteClick(action.record)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </y-table>
    </div>
    <editor
      :visible.sync="visible"
      :target="target"
      @close="handleEditorClose"
    ></editor>
  </div>
</template>

<script>

import ConsultService from "@/services/consult";

import Editor from "../editor";
const defaultFilters = {
  type: "SUPPLY",
};
const defaultEntity = {
  properties: {},
};
export default {
  components: { Editor },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
    },
    sorter: {
      type: Array,
    },
    entity: {
      type: Object,
      default: () => {
        return JSON.parse(JSON.stringify(defaultEntity));
      },
    },
  },
  data() {
    return {
      service: ConsultService,
      visible: false,
      visibleSortModal: false,
      privateFilters: JSON.parse(JSON.stringify(defaultFilters)),
      target: {},
      columns: [
        {
          dataIndex: "name",
          title: "姓名",
          sorter: true,
        },
        {
          dataIndex: "companyName",
          title: "公司名称",
          sorter: true,
        },
        {
          dataIndex: "peopleNum",
          title: "员工人数",
          sorter: true,
        },
        {
          dataIndex: "email",
          title: "邮箱",
        },
        {
          dataIndex: "phoneNumber",
          title: "联系电话",
        },
        {
          dataIndex: "content",
          title: "内容",
        },
        {
          dataIndex: "sequence",
          title: "权重",
          scopedSlots: { customRender: "sequence" },
          sorter: true,
        },
        {
          dataIndex: "enabled",
          title: "是否上架",
          scopedSlots: { customRender: "enabled" },
        },
        {
          dataIndex: "creater",
          title: "创建人",
        },
        {
          dataIndex: "createTime",
          title: "创建时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          dataIndex: "updater",
          title: "更新人",
        },
        {
          dataIndex: "updateTime",
          title: "更新时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  watch: {
    filters: {
      handler: async function (val) {
        this.privateFilters = val;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleClickAdd() {
      this.target = {};
      this.visible = true;
    },
    handleEditClick(record) {
      this.target = record;
      this.visible = true;
    },
    handleEditorClose() {
      this.target = JSON.parse(JSON.stringify(defaultEntity));
      this.privateFilters = JSON.parse(JSON.stringify( this.filters));
    },
    async handleDeleteClick(record) {
      try {
        this.loading = true;
        await this.service.deleteEntity(record);
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.privateFilters = JSON.parse(JSON.stringify( this.filters));
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>
